'use client'

import { useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip'

import { PlanDetails } from '@/types/plan'

import { Lang } from '@/i18n'
import { Country, PaginatedList } from '@/types'

import 'react-tooltip/dist/react-tooltip.css'

import NoResults from './no-results'
import CountriesList from './countries-list'
import { useSearch } from './search-provider'
import OffersSkeleton from './offers-skeleton'
import OfferListItem from './offer-list-item'
import TableHeader from './table-header'
import { filterData } from './helpers'

interface ListProps {
  lang: Lang
  data: PaginatedList<PlanDetails>
}

const List = ({ lang, data }: ListProps) => {
  const { filters, setFilters } = useSearch()
  const [selectedPlan, setSelectedPlan] = useState<PlanDetails>()

  const filtered = filterData(data, {
    sortBy: filters.sortBy,
    capacity: filters.capacity,
    validity: filters.validity,
  })

  useEffect(() => {
    setFilters(prevState => ({ ...prevState, loading: false, count: data?.items?.length, showing: filtered?.length || 0 }))
  }, [setFilters, data, filters.capacity, filters.validity, filters.sortBy])

  if (filters.loading) {
    return (
      <div className='mb-56'>
        <TableHeader showCountriesColumn={filters.countries.length > 1} />
        <OffersSkeleton count={6} />
      </div>
    )
  }

  if (!filtered?.length) {
    return <NoResults />
  }

  // check number of countries for each item and depending on that display additional countries if exist
  const showCountriesColumn = data?.items?.some(offer => offer.countries.length > 1)
  return (
    <div className='mb-56'>
      <TableHeader showCountriesColumn={showCountriesColumn} />
      <div className='grid gap-y-4 md:gap-y-6'>
        {filtered?.map(offer => (
          <OfferListItem bestOffer={!filters.countries.length} setSelectedPlan={setSelectedPlan} showCountriesColumn={showCountriesColumn} lang={lang} offer={offer} key={offer.id} />
        ))}
      </div>
      {/*modals and tooltip containers*/}
      <Tooltip variant='light' id='my-tooltip' className='z-50 bg-white !text-sm max-w-56 border border-gray-300 rounded-md' classNameArrow='border-b border-r border-gray-300' />
      <CountriesList open={!!selectedPlan} plan={selectedPlan} onClose={() => setSelectedPlan(undefined)} />
    </div>
  )
}

export default List
