'use client'

import { Button } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment } from 'react'
import Link from 'next/link'
import { InformationCircleIcon, SparklesIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { TopUpType } from '@prisma/client'

import CountryFlag from '@/components/country-flag/country-flag'
import CapacityIcon from '@/components/icons/capacity'
import CountriesIcon from '@/components/icons/countries'
import ValidityIcon from '@/components/icons/validity'
import PriceIcon from '@/components/icons/price'
import { PlanDetails } from '@/types/plan'
import { Country } from '@/types'
import { Lang } from '@/i18n'

import { getCardType } from '../helpers'
import SpeedMark from './speed-mark'

interface OfferItemProps {
  lang: Lang
  offer: PlanDetails
  bestOffer: boolean
  showCountriesColumn: boolean
  setSelectedPlan: (plan: PlanDetails) => void
}

const OfferListItem = ({ lang, bestOffer, offer, setSelectedPlan, showCountriesColumn }: OfferItemProps) => {
  const { t } = useTranslation()

  const slug = [offer.formatedPlanEn, offer.id].join('-')
  // const href = `/${lang}/offers/${offer.id}/checkout`
  const href = `/${lang}/offers/${encodeURI(slug)}/checkout`

  let flag = null
  if (bestOffer) {
    const country = offer.countries.length === 1 ? offer.countries[0] : ({ code: offer.region, name: offer.region } as Country)
    flag = <CountryFlag size='md' country={country} />
  }

  const title = (
    <>
      <div className='flex items-center gap-x-1 lg:gap-x-2'>
        <div>
          <span className='sr-only'>{t('OfferCard.Text.SpeedDescription', { speed: offer.speed })}</span>
          <SpeedMark className='size-6 md:size-7' speed={offer.speed} />
        </div>
        <div className={clsx('flex items-center gap-x-2 text-base xl:text-lg leading-7 font-semibold text-blue-950 truncate')}>
          {flag}
          {offer.planName}
        </div>
      </div>
      <div className='hidden md:block md:ml-8 lg:ml-9 text-sm text-gray-400'>
        <span className='sr-only'>{t('OfferCard.Text.CardTypeDescription')}</span>
        {t(getCardType(offer.cardType)).toUpperCase()}
      </div>
    </>
  )

  const attributes = (
    <>
      {offer.activationType === 'API' ? (
        <div data-tooltip-id='my-tooltip' data-tooltip-html={t('OfferCard.Text.ActivationPolicyInstallDescription')}>
          <span className='sr-only'>{t('OfferCard.Text.ActivationPolicyInstallText')}</span>
          <span className='inline-flex gap-x-1 items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-900 ring-1 ring-inset ring-red-600/10'>
            {t('OfferCard.Text.ActivationPolicyInstallLabel')}
            <InformationCircleIcon className='w-4 h-4' />
          </span>
        </div>
      ) : (
        <div data-tooltip-id='my-tooltip' data-tooltip-html={t('OfferCard.Text.ActivationPolicyConnectDescription')}>
          <span className='sr-only'>{t('OfferCard.Text.ActivationPolicyConnectText')}</span>
          <span className='inline-flex gap-x-1 items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20'>
            {t('OfferCard.Text.ActivationPolicyConnectLabel')}
            <InformationCircleIcon className='w-4 h-4' />
          </span>
        </div>
      )}
      {offer.topup === TopUpType.ENABLED && (
        <div data-tooltip-id='my-tooltip' data-tooltip-html={t('OfferCard.Text.TopupDescription')}>
          <span className='inline-flex gap-x-1 items-center rounded-md bg-blue-70 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10'>
            {t('OfferCard.Text.Topup')}
            <InformationCircleIcon className='min-w-4 h-4' />
          </span>
        </div>
      )}
      {!!offer.throttle && (
        <div data-tooltip-id='my-tooltip' data-tooltip-html={t('OfferCard.Text.UnthrottleDataDescription', { unthrottleData: offer.unthrottleData, throllteSpeed: offer.throllteSpeed })}>
          <span className='inline-flex gap-x-1 items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-900 ring-1 ring-inset ring-red-600/10'>
            {offer.unthrottleData}/24h
            <InformationCircleIcon className='min-w-4 h-4' />
          </span>
        </div>
      )}
      {offer.recomended && !!offer.rate && (
        <div data-tooltip-id='my-tooltip' data-tooltip-html={t('OfferCard.Text.PopularDescription')}>
          <span className='inline-flex gap-x-1 items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20'>
            {t('OfferCard.Text.Popular')}
            <SparklesIcon className='min-w-4 h-4' />
          </span>
        </div>
      )}
    </>
  )

  // when changing paddings and cols please make sure that you change and columns
  return (
    <div
      className={clsx(
        'bg-white px-2 py-3 xl:px-4 xl:py-4 border rounded-md z-40',

        !bestOffer && offer.recomended && offer.rate ? '!bg-green-50 border-green-600' : 'border-transparent',
      )}
    >
      <div className={clsx('grid grid-cols-6 gap-y-1 md:gap-y-0', showCountriesColumn ? 'md:grid-cols-10' : 'md:grid-cols-9')}>
        {/* Title and Attributes row - mobile colum*/}
        <div className='flex col-span-6 md:hidden'>
          <div className='flex flex-col flex-shrink'>{title}</div>
          <div className='flex flex-1 justify-end flex-wrap gap-x-1 gap-y-1 md:justify-start md:items-center'>{attributes}</div>
        </div>

        {/* Title - desktop column */}
        <div className='hidden md:flex col-span-2 md:col-span-3 flex-col'>
          <div className='flex items-center gap-x-1 lg:gap-x-2'>
            <div>
              <span className='sr-only'>{t('OfferCard.Text.SpeedDescription', { speed: offer.speed })}</span>
              <SpeedMark className='size-6 md:size-7' speed={offer.speed} />
            </div>
            <div className={clsx('flex items-center gap-x-2 text-base xl:text-lg leading-7 font-semibold text-blue-950 truncate')}>
              {flag}
              {offer.planName}
            </div>
          </div>
          <div className='hidden md:block md:ml-8 lg:ml-9 text-sm text-gray-400'>
            <span className='sr-only'>{t('OfferCard.Text.CardTypeDescription')}</span>
            {t(getCardType(offer.cardType)).toUpperCase()}
          </div>
        </div>

        {/* Attributes - desktop colum*/}
        <div className='hidden md:flex col-span-4 md:col-span-2 flex-wrap gap-x-1 gap-y-1 justify-end md:justify-start md:items-center'>{attributes}</div>

        {showCountriesColumn && (
          <div className={clsx('hidden items-center md:flex gap-x-2', offer.countries.length === 1 && 'justify-start')}>
            {offer.countries.length > 1 ? (
              <div className='flex gap-x-2 items-center cursor-pointer overflow-hidden' onClick={() => setSelectedPlan(offer)}>
                <CountriesIcon className='min-w-4 h-4 hidden xl:inline-block' />
                <span className='underline text-red-500 xl:font-medium truncate'>
                  {offer.countries.length} <span className='truncate'>{t('OfferCard.Text.Country', { count: offer.countries.length })}</span>
                </span>
              </div>
            ) : (
              <div className='flex items-center justify-center gap-x-2 overflow-hidden'>
                <span className='hidden xl:block min-w-6 h-1' />
                <div className='font-light text-gray-500 text-sm truncate'>{offer.countries[0].name}</div>
              </div>
            )}
          </div>
        )}

        {/*Capacity column*/}
        <div className='col-span-2 md:col-span-1 flex items-center md:justify-start gap-x-2'>
          <span className='sr-only'>{t('OfferCard.Text.Data')}</span>
          <CapacityIcon className='min-w-4 h-4 text-gray-400 inline-block md:hidden xl:inline-block' />
          <span className='font-medium'>{offer.formatedVolume}</span>
        </div>

        {/*Validity column*/}
        <div className='col-span-2 md:col-span-1 flex items-center md:justify-start gap-x-2'>
          <span className='sr-only'>{t('OfferCard.Text.Validity')}</span>
          <ValidityIcon className='min-w-4 h-4 text-gray-400 inline-block md:hidden xl:inline-block' />
          <span className='font-medium'>{offer.formatedValidity}</span>
        </div>

        {/*Price column*/}
        <div className='col-span-2 md:col-span-1 flex items-center justify-end md:justify-start gap-x-2'>
          <PriceIcon className='min-w-4 h-4 text-gray-400 inline-block md:hidden xl:inline-block' />
          <span className='sr-only'>{t('OfferCard.Text.Price')}</span>
          <div className='flex flex-row md:flex-row-reverse lg:flex-row justify-start md:justify-end lg:justify-start items-center gap-x-2 md:gap-x-0 lg:gap-x-2 truncate'>
            <span className='text-gray-400 text-sm truncate'>{offer.formatedPrice.split(/\s/)[0]}</span> <span className='font-medium leading-7'>{offer.formatedPrice.split(/\s/)[1]}</span>
          </div>
        </div>

        {/* Countries and Card type columns for mobile screens*/}
        <div className='col-span-2 flex items-center gap-x-2 md:hidden text-sm md:text-base'>
          <div className='flex items-center gap-x-2'>
            <span className='sr-only'>{t('OfferCard.Text.CardTypeDescription')}</span>
            <svg className='min-w-4 h-4' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 25' height='25' width='24'>
              <path
                strokeWidth='1.5'
                stroke='#9095a7'
                d='M10.3175 9.7476C14.3642 9.7476 17.6448 8.42423 17.6448 6.79177C17.6448 5.15931 14.3642 3.83594 10.3175 3.83594C6.27076 3.83594 2.99023 5.15931 2.99023 6.79177C2.99023 8.42423 6.27076 9.7476 10.3175 9.7476Z'
              ></path>
              <path strokeLinejoin='round' strokeLinecap='round' strokeWidth='1.5' stroke='#9095a7' d='M2.99023 12.5V18.2737C2.99023 18.2737 2.99023 21.1615 10.2835 21.1615'></path>
              <path strokeLinejoin='round' strokeLinecap='round' strokeWidth='1.5' stroke='#9095a7' d='M17.6445 12.5002V6.72656'></path>
              <path strokeLinejoin='round' strokeLinecap='round' strokeWidth='1.5' stroke='#9095a7' d='M2.99023 6.72656V12.5002C2.99023 12.5002 2.99023 15.3871 10.2835 15.3871'></path>
              <path strokeLinejoin='round' strokeLinecap='round' strokeWidth='1.5' stroke='#9095a7' d='M19.3984 21.0519V15.6484'></path>
              <path strokeLinejoin='round' strokeLinecap='round' strokeWidth='1.5' stroke='#9095a7' d='M21.0099 19.5L19.3975 21.0557L17.7852 19.5'></path>
              <path strokeLinejoin='round' strokeLinecap='round' strokeWidth='1.5' stroke='#9095a7' d='M14.8906 15.7266V21.1302'></path>
              <path strokeLinejoin='round' strokeLinecap='round' strokeWidth='1.5' stroke='#9095a7' d='M13.2773 17.2823L14.8896 15.7266L16.502 17.2823'></path>
            </svg>
            <div className='text-sm text-gray-500'>{t(getCardType(offer.cardType)).toUpperCase()}</div>
          </div>
        </div>
        <div className='col-span-2 flex items-center gap-x-2 md:hidden text-base md:text-base'>
          {offer.countries.length > 1 && (
            <div className='flex items-center gap-x-2'>
              <CountriesIcon className='min-w-4 h-4 text-gray-400 inline-block md:inline-block' />
              <div className='font-medium underline text-red-500 cursor-pointer truncate overflow-x-hidden' onClick={() => setSelectedPlan(offer)}>
                {t('OfferCard.Text.NumOfCountries', { count: offer.countries.length })}
              </div>
            </div>
          )}
        </div>

        {/*Buy button*/}
        <div className='col-span-2 md:col-span-1 flex justify-end items-center'>
          <Link prefetch={false} href={href}>
            <Button as={Fragment}>
              {({ hover, active }) => (
                <button
                  className={clsx(
                    'rounded-md w-full py-0.5 px-6 text-sm md:w-auto md:py-1 xl:px-8 font-semibold border border-red-500',
                    !hover && !active && 'bg-red-500 text-white',
                    hover && !active && 'bg-white text-red-500',
                  )}
                >
                  {t('OfferCard.Action.BuyNow')}
                </button>
              )}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default OfferListItem
