import { CardType, PaginatedList } from '@/types'
import { PlanDetails } from '@/types/plan'
import { CapacityOptionItem, SortOptionItem } from '@/app/[lang]/offers/types'

export function getCardType(cardType: CardType | any) {
  switch (cardType) {
    case CardType.DataOnly:
      return 'OfferCard.Text.DataCard'

    case CardType.VoiceAndData:
      return 'OfferCard.Text.VoiceCard'

    default:
      return ''
  }
}

export function normalizeString(str: string, replace: string = '') {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, replace)
}

export function filterCountries(country: string, inputValue: string) {
  if (!inputValue) {
    return true
  }

  return normalizeString(country.toLowerCase()).includes(normalizeString(inputValue.toLowerCase()))
}

export function getOfferTitle(offer: PlanDetails) {
  if (process.env.NODE_ENV === 'development') {
    return offer.formatedPlan + ' - ' + offer.id
  }
  return offer.formatedPlan
}

interface FilterDataArgs {
  sortBy: SortOptionItem
  capacity: CapacityOptionItem[]
  validity: CapacityOptionItem[]
}

export function filterData(data: PaginatedList<PlanDetails>, { capacity, validity, sortBy }: FilterDataArgs): PlanDetails[] {
  const filtered = data.items.filter(plan => {
    // Check if filtering by capacity or validity is needed
    const considerFilter = capacity.length > 0 || validity.length > 0
    if (!considerFilter) {
      return true // No filtering, return all items
    }

    // Check for capacity match
    const capacityMatch = capacity.length === 0 || capacity.some(c => c.config.some(q => plan.volume >= q.from && plan.volume <= q.to))

    // Check for validity match
    const validityMatch = capacityMatch || validity.some(v => v.config.some(q => plan.validity >= q.from && plan.validity <= q.to))

    // Return true if at least one condition is satisfied
    return capacityMatch || validityMatch
  })

  const [property, order] = sortBy.value.split('=')
  if (property === 'price') {
    return filtered.sort((a, b) => (order === 'asc' ? a.price - b.price : b.price - a.price))
  } else if (property === 'capacity') {
    return filtered.sort((a, b) => {
      const aCapacity = a.volume || Number.POSITIVE_INFINITY
      const bCapacity = b.volume || Number.POSITIVE_INFINITY
      return order === 'asc' ? aCapacity - bCapacity : bCapacity - aCapacity
    })
  } else if (property === 'validity') {
    return filtered.sort((a, b) => (order === 'asc' ? a.validity - b.validity : b.validity - a.validity))
  }

  return filtered
}
