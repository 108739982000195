import { Lang } from '@/i18n'
import { ProviderCredentials } from '@prisma/client'
import { Transaction } from 'mongodb'
import { NextRequest } from 'next/server'

type LangMap = {
  [key in Lang]: string
}

export interface CountryInfo extends LangMap {
  id: number
  alpha2: string
  alpha3: string
  local?: string | null
  [key: string]: string | number | null | undefined
}

export enum OfferExpirationType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  LIFETIME = 'LIFETIME',
  UNKNOWN = 'UNKNOWN',
}

export enum PlanSearchType {
  LOCAL = 'LOCAL',
  REGIONAL = 'REGIONAL',
  TOP = 'TOP',
}

export const OFFER_EXPARATION_TYPE_MAP: { [key: string]: OfferExpirationType } = {
  'no expiration': OfferExpirationType.LIFETIME,
  daily: OfferExpirationType.DAILY,
  weekly: OfferExpirationType.WEEKLY,
  monthly: OfferExpirationType.MONTHLY,
  'per week': OfferExpirationType.WEEKLY,
  'per month': OfferExpirationType.MONTHLY,
  'per year': OfferExpirationType.YEARLY,
  yearly: OfferExpirationType.YEARLY,
}

export type Country = {
  name: string
  code: string
  popular?: number
}

export enum SpeedType {
  G2 = '2G',
  G3 = '3G',
  G4 = '4G',
  G5 = '5G',
}

export enum CardType {
  DataOnly = 'DATA_ONLY',
  VoiceAndData = 'VOICE_AND_DATA',
}

export type PreferredConfiguration = {
  lng: string
  currency: string
}

export type AuthUser = {
  id: string
  name: string
  username: string
  email: string
  externalId?: string
}

export interface Request<T = {}> extends NextRequest {
  user: AuthUser
  token?: string
  model: T
  query?: { [key: string]: string }
  url: string
  method: string
  json: () => Promise<any>

  formData: () => Promise<FormData>
}

export interface Response<T = {}> extends NextRequest {
  params: { [key: string]: string }
  setHeader: (key: string, value: string) => void
  //redirect: (...args: any) => void
  test: (data: T) => void
}

export enum CreditType {
  Add = 'ADD',
  Deduct = 'DEDUCT',
}

export type ServicePlans = {
  id: string
  volume: number
  validity: number
  countries: string[]
  source: string
  price: number
  region?: string
  speed: SpeedType
  cardType: CardType
  rate?: number
  throttleSpeed?: string
  unthrottleData?: string
}

export type ServiceUser = {
  id: string
  credits?: number
  credentials?: ProviderCredentials[]
}

export type ServiceOptions = {
  user?: ServiceUser
  currency: string
  lang: string
  confirm?: boolean
  tx?: Transaction
}

export type ResponseStatus = {
  ok: boolean
}

export type PaginatedList<T> = {
  items: T[]
  page: number
  limit: number
  total: number
  count: number
}

export type PaginatedQuery = {
  page?: number
  limit?: number
  orderBy?: { [key: string]: 'asc' | 'desc' }[]
  where?: any
  include?: any
}
